.base {
  width: 100%;
  display: flex;
  overflow: hidden;
  height: min-content;
  max-height: calc(70vh - var(--header-height));
  @media (--large) {
    max-height: calc(100vh - var(--header-height));
  }

  &.full {
    aspect-ratio: inherit !important;
    & > * {
      width: 100%;
      object-fit: cover;
      max-height: calc(100vh - var(--header-height));
      aspect-ratio: inherit !important;
    }
  }
  &.left {
    justify-content: flex-start;
    margin-right: auto;
    max-width: 75%;

    @media (--large) {
      max-width: 100%;
    }

    & .media_positioner {
      max-width: 100%;
      object-position: left;
    }
  }

  &.center {
    justify-content: center;
    margin-inline: auto;
    max-width: 75%;
    @media (--large) {
      max-width: 100%;
    }
  }

  &.right {
    justify-content: flex-end;
    margin-left: auto;
    max-width: 75%;

    @media (--large) {
      max-width: 100%;
    }

    & .media_positioner {
      max-width: 100%;
      object-position: right;
    }
  }
  &.inset {
    padding: 2vw;
  }

  & .media_positioner {
    width: auto;
    max-height: calc(100vh - var(--header-height));
  }

  & img {
    object-fit: contain;
    max-width: 100%;
    height: auto;
    object-fit: cover;
    background-color: var(--color-primary-20);
  }
}

.desktop {
  display: none;
  @media (--large) {
    display: block;
  }
  &[data-no-mobile="true"] {
    display: block !important;
  }
}

.mobile {
  display: block;
  @media (--large) {
    display: none;
  }
  &[data-no-mobile="true"] {
    display: none !important;
  }
}
