.outerwrapper {
  --inset-width: 0rem;
  position: relative;
  /* display: flex; */
  height: 100%;
  min-width: 0;
  overflow: hidden;
  @media (--large) {
    height: calc(100%- var(--inset-width) * 2);
  }
  margin: var(--inset-width);
  &.inset {
    --inset-width: 2rem;
  }
}

.content {
  width: 100%;
  height: 100%;
  /* flex-grow: 1; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-block: 4rem;
  z-index: 2;
}

.content__main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8svh;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  flex-grow: 1;
}

.secondary__title {
  flex-grow: 2;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.opacity__layer {
  height: 100%;
  width: 100%;
  position: absolute;
  inset: 0;
  z-index: 1;
}

.hero-pramble {
  @extend %font-3__B;
}

.heading {
  white-space: pre-wrap;
  text-align: center;
  z-index: 2;
}

.images__wrapper {
  position: absolute;
  inset: 0;
  height: 100%;
  display: grid;
  @media (--mobile-only) {
    grid-template-columns: 1fr !important; /* override inline styles */
  }
}

.image__wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.button__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (--large) {
    gap: 4rem;
    flex-direction: row;
  }
}

.cta__text {
  @media (--mobile-only) {
    order: 1;
    padding-top: 2rem;
  }
  &.right {
    text-align: right;
  }
}
