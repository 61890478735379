.root {
  max-width: 100vw;
  overflow-x: scroll;
  container-name: mediaGallery;
  container-type: inline-size;
  @media (--large) {
    overflow: unset;
  }
}

.grid {
  display: inline-flex;
  gap: 0.5rem;
  margin: 1rem;
  @media (--large) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2vw;
    margin: 0 var(--page-inset);
    & {
      @container (max-width: 1024px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        & > *:nth-child(2n) {
          transform: translateY(50%);
        }
      }
    }
  }
}

.item {
  width: 80vw;
  @media (--small) {
    width: 50vw;
  }
  @media (--large) {
    width: auto;
  }
}
