.hero {
  --nav-height: 4.375rem;
  min-height: calc(100vh - var(--nav-height));
  width: 100%;
  display: grid;
}

.heroLow {
  height: 60vh;
}

.container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  &[data-length>="2"] {
    grid-template-rows: 1fr 1fr;
    grid-gap: 2rem;
    padding-bottom: 2rem;
  }
  @media (--large) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    &[data-length>="2"] {
      grid-gap: 0;
      padding-bottom: 0;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      &[data-double-inset="true"] {
        & > div:first-child {
          margin-right: 1rem;
        }
        & > div:last-child {
          margin-left: 1rem;
        }
      }
    }
  }
}
